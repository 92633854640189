import moment from 'moment';
import _ from 'lodash';
import { getRoundedData } from 'habitual-analytics/common/formatter/number';
import {
  PRETTIER_FORMAT_WITH_SECONDS,
  TIME_FORMAT,
} from '../../dateUtils/dateFormats';
import {
  statusConfigs,
  transactionTypes,
} from '../../constants/habitual-configs';
import { getFormattedMoney } from '../../utils/money';
import {
  convertToNumber,
  getCancellableOrderStatus,
  isValidSymbolForInsta,
  isOrderPartiallyExecuted,
  parseExchangeTokenTradingSymbol,
} from '../utils';
import { getDefaultProductCode } from '../tradingSymbolParser';
import { 
  getInstrumentDetailsByExchangeTokens 
} from 'habitual-analytics/api/services/getInstrumentDetailsByExchangeTokens';
import { getExchangeToken } from 'habitual-analytics/api/services/getExchangeToken';
import { parseOrderDetailToGetTradingSymbolObj } from './workmate';
import {
  getDynamicAppConfigs,
  initDynamicAppConfigs,
} from 'habitual-analytics/constants/dynamicAppConfigs';
import MarketUtility from 'habitual-analytics/utils/marketUtility';

const parseOrderType = (orderType) => {
  switch (orderType) {
    case 'Limit':
    case 'L':
      return 'Limit';
    case 'MKT':
      return 'Market';
    case 'SL':
      return 'StopLimit';
    case 'SL-M':
      return 'StopMarket';
    default:
      return orderType;
  }
};
const defaultParseOrderType = (orderType) => {
  let type;
  switch (_.toLower(orderType)) {
    case 'limit':
    case 'l':
      type = 'L';
      break;
    case 'market':
      type = 'MARKET';
      break;
    case 'stoplimit':
      type = 'SL';
      break;
    case 'stopmarket':
      type = 'SL-M';
      break;
    default:
      type = '';
  }
  return type;
};

const parseProductCode = (pcode) => {
  let productCode;
  switch (_.toLower(pcode)) {
    case 'cnc':
      productCode = 'CNC';
      break;
    case 'mis':
      productCode = 'MIS';
      break;
    case 'nrml':
      productCode = 'NRML';
      break;
    default:
      productCode = '';
  }
  return productCode;
};

const sanitizeAndParseOrderStatus = (orderDetail) => {
  let status = statusConfigs.placed.value;

  switch (_.lowerCase(orderDetail.OrderStatus)) {
    case 'cancelled':
    case 'Cancelled':
      status = statusConfigs.cancelled.value;
      break;
    case 'complete':
    case 'filled':
      status = statusConfigs.executed.value;
      break;
    case 'rejected':
    case 'Rejected':
      status = statusConfigs.failed.value;
      break;
    case isOrderPartiallyExecuted(
      orderDetail.status,
      orderDetail.qty,
      orderDetail.fillshares
    ):
      status = statusConfigs.partiallyExecuted.value;
      break;
    default:
      status = statusConfigs.placed.value;
      break;
  }

  return status;
};

const parseOrderBook = (orderDetail) => {
  const tradingSymbolObj = parseOrderDetailToGetTradingSymbolObj(orderDetail);
  const tradingSymbol = tradingSymbolObj.toString();
  const exchange = _.get(orderDetail, 'ExchangeSegment', '');

  if (
    !isValidSymbolForInsta(exchange, tradingSymbol) ||
    !_.includes(MarketUtility.getSymbols(), tradingSymbolObj?.symbol)
  ) {
    return null;
  }

  const productType = _.get(orderDetail, 'OrderType', '').toUpperCase();
  const productCode = _.get(orderDetail, 'ProductType', '').toUpperCase();
  const tradedQty = _.get(orderDetail, 'CumulativeQuantity', 0);
  const quantity = Number(_.get(orderDetail, 'OrderQuantity', 0));
  const tradedPrice = _.get(orderDetail, 'OrderAverageTradedPrice', 0);
  const tradedTime = _.get(orderDetail, 'OrderGeneratedDateTime', '').split(
    ' '
  )?.[1];
  const status = sanitizeAndParseOrderStatus(orderDetail);
  const isCancellableOrder = getCancellableOrderStatus(status);
  const failedReason = _.get(orderDetail, 'CancelRejectReason', '');

  return {
    tradingSymbolObj: tradingSymbolObj,
    time: moment(tradedTime, TIME_FORMAT)?.format(PRETTIER_FORMAT_WITH_SECONDS),
    type:
      _.get(orderDetail, 'OrderSide', '') === 'SELL'
        ? transactionTypes?.sell.value
        : transactionTypes?.buy?.value,
    status: isCancellableOrder ? statusConfigs.pending.value : status,
    isCancellableOrder,
    failedReason,
    extraDetails: {
      product: `${productCode} / ${productType}`,
      qty: `${tradedQty} / ${quantity}`,
      tradedPrice,
      orderNo: _.get(orderDetail, 'AppOrderID', ''),
      defaultProductType: defaultParseOrderType(productType),
      defaultProductCode: getDefaultProductCode(productCode, tradingSymbolObj),
    },
  };
};

const parseTradeBook = (orderDetail) => {
  const tradingSymbolObj = parseOrderDetailToGetTradingSymbolObj(orderDetail);
  const tradingSymbol = tradingSymbolObj.toString();
  const exchange = _.get(orderDetail, 'ExchangeSegment', '');

  if (
    !isValidSymbolForInsta(exchange, tradingSymbol) ||
    !_.includes(MarketUtility.getSymbols(), tradingSymbolObj?.symbol)
  ) {
    return null;
  }

  const productType = _.get(orderDetail, 'OrderType', '').toUpperCase();
  const productCode = _.get(orderDetail, 'ProductType', '').toUpperCase();
  const tradedQuantity = Number(_.get(orderDetail, 'CumulativeQuantity', 0));
  const quantity = Number(_.get(orderDetail, 'OrderQuantity', 0));
  const status = statusConfigs.executed.label;

  return {
    tradingSymbolObj: tradingSymbolObj,
    time: moment(
      _.get(orderDetail, 'OrderGeneratedDateTime', '')?.split(' ')?.[1],
      TIME_FORMAT
    ).format(PRETTIER_FORMAT_WITH_SECONDS),
    type:
      _.get(orderDetail, 'OrderSide', '') === 'SELL'
        ? transactionTypes?.sell?.value
        : transactionTypes?.buy?.value,
    status,
    extraDetails: {
      product: `${productCode} / ${productType}`,
      qty: `${tradedQuantity} / ${quantity}`,
      tradedPrice: convertToNumber(_.get(orderDetail, 'LastTradedPrice', 0)),
    },
  };
};

const parseSubPositionBook = (orderDetail) => {
  const tradingSymbolObj = parseOrderDetailToGetTradingSymbolObj(
    orderDetail,
    'position'
  );
  const tradingSymbol = tradingSymbolObj.toString();
  const exchange = _.get(orderDetail, 'ExchangeSegment', '');

  if (
    !isValidSymbolForInsta(exchange, tradingSymbol) ||
    !_.includes(MarketUtility.getSymbols(), tradingSymbolObj?.symbol)
  ) {
    return null;
  }
  const ltp = Number(_.get(orderDetail, 'ltp'));
  const qty = Number(_.get(orderDetail, 'Quantity'), 0);

  const buyAvg = _.round(_.get(orderDetail, 'BuyAveragePrice', 0), 2);
  const sellAvg = _.round(_.get(orderDetail, 'SellAveragePrice', 0), 2);
  const unRealisedProfitLoss = (ltp - buyAvg) * qty;
  const type =
    Number(qty) < 0
      ? transactionTypes?.sell?.value
      : transactionTypes?.buy?.value;
  const OpenBuyQuantity = Number(_.get(orderDetail, 'OpenBuyQuantity', 0));
  const OpenSellQuantity = Number(_.get(orderDetail, 'OpenSellQuantity', 0));
  const profitLoss =
    qty == 0
      ? sellAvg * OpenSellQuantity - buyAvg * OpenBuyQuantity
      : unRealisedProfitLoss;

  return {
    ...orderDetail,
    tradingSymbolObj,
    qty,
    buyAvg,
    sellAvg,
    ltp,
    profitLoss,
    symbol: tradingSymbol,
    extraDetails: {
      product: _.get(orderDetail, 'ProductType', ''),
      liveUpdateDetails: {
        symbol: tradingSymbol,
        value: 'ltp',
        key: 'ltp',
      },
      defaultProductCode: getDefaultProductCode(
        _.get(orderDetail, 'ProductType', ''),
        tradingSymbolObj
      ),
      order: orderDetail,
      isOpenPosition: qty !== 0,
      type: qty !== 0 ? type : '',
    },
  };
};

const parsePositionBook = (orderDetail) => {
  const isArrayDetails = _.isArray(orderDetail);
  if (isArrayDetails) {
    return _.map(orderDetail, parseSubPositionBook);
  }

  return parseSubPositionBook(orderDetail);
};

const parseSubHoldingBook = (orderDetail) => {
  const tradingSymbolObj = parseOrderDetailToGetTradingSymbolObj(
    orderDetail,
    'holdings'
  );
  const tradingSymbol = tradingSymbolObj.toString();
  const exchange = tradingSymbolObj.getExchange();

  if (
    !isValidSymbolForInsta(exchange, tradingSymbol) ||
    !_.includes(MarketUtility.getSymbols(), tradingSymbolObj?.symbol)
  ) {
    return null;
  }
  const quantity = _.get(orderDetail, 'HoldingQuantity', 0);
  const orderValue = convertToNumber(_.get(orderDetail, 'BuyAvgPrice', 0));
  const buyValue = quantity * orderValue;
  const ltp = convertToNumber(_.get(orderDetail, 'LTnse', 0));
  const profitLoss = getRoundedData((ltp - orderValue) * quantity);
  const netChg = getRoundedData((profitLoss / buyValue) * 100);

  return {
    tradingSymbolObj,
    ltp,
    Nsetsym: tradingSymbol,
    profitLoss,
    extraDetails: {
      quantity: `${quantity} (T1:${_.get(orderDetail, 'HoldingQuantity', 0)})`,
      buyAverage: orderValue,
      buyValue: buyValue,
      netChg: `${getFormattedMoney(netChg)}%`,
      liveUpdateDetails: {
        symbol: tradingSymbolObj.toString(),
        value: 'ltp',
        key: 'LTnse',
      },
      order: orderDetail,
    },
  };
};

const parseHoldingsBook = (orderDetail) => {
  const isArrayDetails = _.isArray(orderDetail);
  if (isArrayDetails) {
    return _.map(orderDetail, parseSubHoldingBook);
  }

  return parseSubHoldingBook(orderDetail);
};

const parsePlaceOrder = async (orderConfig) => {
  const { tradingSymbolObj } = orderConfig;
  const formattedTradingSymbol =
    parseExchangeTokenTradingSymbol(tradingSymbolObj);
  const exchangeSegment =
    _.get(orderConfig, 'exch', '') === 'NSE' ? 'NSECM' : 'NSEFO';
  const symbolId = await getExchangeToken(
    formattedTradingSymbol,
    tradingSymbolObj.getExchange()
  );

  return {
    exchangeSegment: exchangeSegment,
    exchangeInstrumentID: symbolId,
    productType: parseProductCode(_.get(orderConfig, 'pCode', '')),
    orderType: parseOrderType(_.get(orderConfig, 'prctyp', '')),
    orderSide:
      _.get(orderConfig, 'transactionType', '') === 'buy' ? 'BUY' : 'SELL',
    timeInForce: _.get(orderConfig, 'ret', ''),
    disclosedQuantity: _.get(orderConfig, 'disCloseQty', '').toString(),
    orderQuantity: _.get(orderConfig, 'qty', 0),
    limitPrice: _.get(orderConfig, 'price', 0),
    stopPrice: _.get(orderConfig, 'trigPrice', 0),
    orderUniqueIdentifier: '123abc',
  };
};

const parseOrderDetails = async (orders, type) => {
  await parseTradingSymbolObjByExchangeToken(orders, type);

  let formattedData = [];
  if (_.isArray(orders)) {
    formattedData = _.map(orders, (orderDetail) => {
      switch (type) {
        case 'order':
          return parseOrderBook(orderDetail);
        case 'trade':
          return parseTradeBook(orderDetail);
        case 'position':
          return parsePositionBook(orderDetail);
        case 'holdings':
          return parseHoldingsBook(orderDetail);
        default:
          return [];
      }
    });
  }
  return formattedData;
};
const brokerSpecificExchangeId = (type) => {
  let exchangeId;
  switch (type) {
    case 'order':
      return (exchangeId = 'ExchangeInstrumentID');
    case 'trade':
      return (exchangeId = 'ExchangeInstrumentID');
    case 'position':
      return (exchangeId = 'ExchangeInstrumentId');
    case 'holdings':
      return (exchangeId = 'ExchangeNSEInstrumentId');
    default:
      exchangeId = 'ExchangeInstrumentID';
  }
  return exchangeId;
};

const parseTradingSymbolObjByExchangeToken = async (orders, type) => {
  const exchangeId = brokerSpecificExchangeId(type);
  const exchangeTokens = _.map(orders, (arr) => _.get(arr, exchangeId, ''));
  const tradingSymbolsByExchangeTokens = _.get(
    getDynamicAppConfigs(),
    'tradingSymbolsByExchangeTokens',
    {}
  );
  const missingTokens = _.filter(_.without(exchangeTokens, 0), (token) =>
    _.isEmpty(tradingSymbolsByExchangeTokens[token])
  );
  if (missingTokens.length > 0) {
    const fetchedDetails = await getInstrumentDetailsByExchangeTokens(
      missingTokens
    );
    const formattedFetchDetails = _.keyBy(fetchedDetails, 'exchange_token');
    const mergedDetails = _.merge({}, exchangeTokens, formattedFetchDetails);
    initDynamicAppConfigs({
      instrumentDetailsByExchangeTokens: mergedDetails,
    });
  }
  return null;
};

const parseModifyOrder = (orderConfig) => {
  const appOrderID = _.get(orderConfig, 'orderNo', '');

  return {
    appOrderID: appOrderID,
    modifiedProductType: parseProductCode(_.get(orderConfig, 'pCode', '')),
    modifiedOrderType: parseOrderType(_.get(orderConfig, 'prctyp', '')),
    modifiedOrderQuantity: _.get(orderConfig, 'qty', 0),
    modifiedDisclosedQuantity: _.get(orderConfig, 'disclosedQty', 0),
    modifiedLimitPrice: _.get(orderConfig, 'price', 0),
    modifiedStopPrice: _.get(orderConfig, 'trigPrice', 0),
    modifiedTimeInForce: 'DAY',
    orderUniqueIdentifier: '123abc',
  };
};

export {
  parseOrderDetails,
  parseTradeBook,
  parseOrderBook,
  parsePositionBook,
  parseModifyOrder,
  parseHoldingsBook,
  parsePlaceOrder,
};
