import Instrument from 'habitual-analytics/models/instrument';
import _ from 'lodash';
import { getBasketFormOrderDetails } from './api';
import { 
  getStrikesPricesBasedOnInstrument 
} from 'habitual-analytics/api/services/getStrikeBasedOnInstrument';
import MarketUtility from 'habitual-analytics/utils/marketUtility';

const findNearestStrikePrice = (strikePrices, strikePrice) => {
  const nearestStrikePrice = _.minBy(strikePrices, (strike) =>
    Math.abs(strike - strikePrice)
  );

  return nearestStrikePrice;
};

export const getStrikeIndexAndStrikeLabel = (atm, strikePrice, stepSize) => {
  const diff = Number(strikePrice) - Number(atm);
  const diffStepCount = _.round(
    (Math.ceil(diff / stepSize) / stepSize) * stepSize
  );

  if (!diffStepCount) {
    return {
      strikeLabel: 'ATM',
      strikeIndex: diffStepCount,
    };
  }

  return {
    strikeLabel: `ATM ${diffStepCount > 0 ? '+' : '-'} ${Math.abs(
      diffStepCount
    )}`,
    strikeIndex: diffStepCount,
  };
};

export const getStrikeConfigs = (newStrike, instrument, atm) => {
  const stepSize = MarketUtility.getSymbolStrikeInterval({
    symbol: instrument,
  });
  return {
    ...getStrikeIndexAndStrikeLabel(atm, newStrike, stepSize),
    strikePrice: newStrike,
  };
};

const getStrikePrice = (strikeIndex, atm, stepSize) => {
  return strikeIndex === 0
    ? `${atm}`
    : `${_.parseInt(atm) + _.parseInt(strikeIndex) * stepSize}`;
};

export const getResolvedLeg = async (leg, instrument, atm, stepSize) => {
  const {
    strikeIndex,
    optionType,
    legExpiry,
    strikePrice: legExistingStrikePrice,
  } = leg;
  const strikePrice =
    legExistingStrikePrice || getStrikePrice(strikeIndex, atm, stepSize);

  const strikePrices = await getStrikesPricesBasedOnInstrument({
    expiryDate: legExpiry,
    instrument,
  });
  const newStrikePrice = findNearestStrikePrice(strikePrices, strikePrice);

  const tradingSymbolObj = new Instrument({
    symbol: instrument,
    expiryDate: legExpiry,
    strikePrice: newStrikePrice,
    optionType,
  });

  return {
    ...leg,
    tradingSymbolObj,
    strikePrice: newStrikePrice,
  };
};

export const getResolvedLegs = async (legs, instrument, atm) => {
  const stepSize = MarketUtility.getSymbolStrikeInterval({
    symbol: instrument,
  });

  const updatedLegs = await Promise.all(
    _.map(legs, (leg) => getResolvedLeg(leg, instrument, atm, stepSize))
  );

  const [priceUpdateLegs, remainingLegs] = _.partition(
    updatedLegs,
    (leg) => !(leg?.exitDateTime || leg?.isActivePosition || leg?.datetime)
  );

  if (_.isEmpty(priceUpdateLegs)) return updatedLegs;

  const priceUpdatedLegs = await getBasketFormOrderDetails(
    priceUpdateLegs,
    instrument
  );

  return [...remainingLegs, ...priceUpdatedLegs];
};

export const getDeserializedLegs = (legs, instrument) => {
  return _.map(legs, (leg) => {
    const { strikePrice, optionType, legExpiry } = leg;
    const tradingSymbolObj = new Instrument({
      symbol: instrument,
      expiryDate: legExpiry,
      strikePrice,
      optionType,
    });

    return {
      ...leg,
      tradingSymbolObj,
    };
  });
};
