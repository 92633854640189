import { markets } from 'zeequant-constants';
import { getDynamicAppConfigs } from '../constants/dynamicAppConfigs';
import { avoidedInstruments } from '../constants/habitual-configs';
import { getCurrentDate } from 'habitual-analytics/utils/datetime';
import { DATE_FORMAT } from '../dateUtils/dateFormats';
import moment from 'moment';

// Reference markets.md in zeequant-constants

const MarketUtility = (() => {
  const { MARKET } = window?.instaConstants || {};

  let currentMarketConfig = markets[MARKET] || {};

  const instrumentFilterFn = (configs) => {
    const { isBseEnabled, bseEnabledInstruments } =
      getDynamicAppConfigs()?.domainConfigs;

    if (isBseEnabled) {
      return configs;
    }

    return _.chain(configs)
      .filter(({ symbol }) => !_.includes(bseEnabledInstruments, symbol))
      .filter(({ symbol }) => !_.includes(avoidedInstruments, symbol))
      .value();
  };

  return {
    init: (marketName) => {
      currentMarketConfig = markets[marketName] || {};
    },
    getSymbols: () => {
      return currentMarketConfig?.utility.getSymbols({
        filterFn: instrumentFilterFn,
      });
    },
    getIndexSymbols: () => {
      return currentMarketConfig?.utility.getSymbols({
        isIndex: true,
        filterFn: instrumentFilterFn,
      });
    },
    getIsinCodeBySymbol: (symbol) => {
      const getSymbolConfig = currentMarketConfig?.utility.getSymbolConfig({
        symbol,
      });

      return getSymbolConfig?.isinCode || '';
    },
    getAllIsinCodesWithSymbols: () => {
      const symbols = MarketUtility.getSymbols();
      return _.reduce(symbols, (result, { symbol, isinCode }) => {
        result[symbol] = isinCode;
        return result;
      });
    },
    getAllIsinCodes: () => {
      const symbols = MarketUtility.getAllIsinCodesWithSymbols();
      return _.values(symbols);
    },
    getSymbolByIsinCode: (isinCode) => {
      const symbols = MarketUtility.getAllIsinCodesWithSymbols();
      return _.findKey(symbols, (value) => value === isinCode) || '';
    },
    getValidMarketDate: (date) => {
      return currentMarketConfig?.utility.getValidMarketDate({
        date,
      });
    },
    getWeekendWorkingDates: () => {
      return currentMarketConfig?.weekendWorkingDates || [];
    },
    getHolidayDates: () => {
      return currentMarketConfig?.holidayDates || [];
    },
    getExpiryDates: currentMarketConfig?.utility.getExpiryDates,
    getExpiredExpiryDates: ({
      symbol,
      expiryType = '*',
      compareDate = getCurrentDate().format(DATE_FORMAT),
    }) => {
      return MarketUtility.getExpiryDates({
        symbol,
        expiryType,
        filterFn: (data) => data.filter((d) => d < compareDate),
        uniqueValue: compareDate,
      });
    },
    getUpComingExpiryDates: ({
      symbol,
      expiryType = '*',
      compareDate = getCurrentDate().format(DATE_FORMAT),
    }) => {
      return MarketUtility.getExpiryDates({
        symbol,
        expiryType,
        filterFn: (data) => data.filter((d) => d >= compareDate),
        uniqueValue: compareDate,
      });
    },
    getMonthlyExpiryDates: ({ symbol }) => {
      return MarketUtility.getExpiryDates({
        symbol,
        expiryType: 'monthly',
      });
    },
    getCurrentExpiryDate: ({
      symbol,
      compareDate = getCurrentDate().format(DATE_FORMAT),
    }) => {
      return _.chain(
        MarketUtility.getUpComingExpiryDates({
          symbol,
          compareDate,
          expiryType: '*',
        })
      )
        .first()
        .value();
    },
    getCurrentMonthExpiryDates: ({
      symbol,
      compareDate = getCurrentDate().format(DATE_FORMAT),
    }) => {
      // Extract the year and month from the reference date
      const referenceYearMonth = compareDate.slice(0, 7);

      return _.chain(
        MarketUtility.getUpComingExpiryDates({
          symbol,
          compareDate,
          expiryType: 'monthly',
        })
      )
        .filter((expiryDate) => expiryDate.slice(0, 7) === referenceYearMonth)
        .value();
    },
    getNextMonthExpiryDates: ({
      symbol,
      compareDate = getCurrentDate().format(DATE_FORMAT),
    }) => {
      // Extract the year and month from the next month
      const nextYearMonth = (() => {
        const date = moment(compareDate, DATE_FORMAT);
        date.add(1, 'months');
        return date.format('YYYY-MM');
      })();

      return _.chain(
        MarketUtility.getUpComingExpiryDates({
          symbol,
          compareDate,
          expiryType: 'monthly',
        })
      )
        .filter((expiryDate) => expiryDate.slice(0, 7) === nextYearMonth)
        .value();
    },
    getCurrentYearExpiryDates: ({ symbol }) => {
      const currentYear = getCurrentDate().year();
      return _.chain(
        MarketUtility.getUpComingExpiryDates({
          symbol,
          expiryType: 'monthly',
        })
      )
        .filter((expiryDate) => expiryDate.slice(0, 4) === currentYear)
        .value();
    },
    getMonthBasedExpiryDates: ({ symbol = '', month, expiryDates = [] }) => {
      const currentYear = getCurrentDate().year();
      const referenceYearMonth = `${currentYear}-${month}`;
      return _.chain(
        _.isEmpty(expiryDates)
          ? MarketUtility.getMonthlyExpiryDates({ symbol })
          : expiryDates
      )
        .filter((expiryDate) => expiryDate.slice(0, 7) === referenceYearMonth)
        .value();
    },
    getSymbolConfig: ({
      symbol,
      date = MarketUtility.getCurrentExpiryDate({ symbol }),
    }) => {
      const symbolConfig = currentMarketConfig?.utility.getSymbolConfig({
        symbol,
        date,
      });

      return symbolConfig;
    },
    getSymbolLotSize: ({
      symbol,
      date = MarketUtility.getCurrentExpiryDate({ symbol }),
    }) => {
      const symbolConfig = MarketUtility.getSymbolConfig({ symbol, date });
      return _.get(symbolConfig, 'attributes.lotSize', 1);
    },
    getSymbolStrikeInterval: ({
      symbol,
      date = MarketUtility.getCurrentExpiryDate({ symbol }),
    }) => {
      const symbolConfig = MarketUtility.getSymbolConfig({ symbol, date });
      return _.get(symbolConfig, 'attributes.strikeInterval', 1);
    },
    getSymbolFreezeQuantity: ({
      symbol,
      date = MarketUtility.getCurrentExpiryDate({ symbol }),
    }) => {
      const symbolConfig = MarketUtility.getSymbolConfig({ symbol, date });
      return _.get(symbolConfig, 'attributes.freezeQuantity', 0);
    },
  };
})();

export default MarketUtility;
